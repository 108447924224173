<template>
  <footer class="app-footer">
    <div class="copyright-section">
      Copyright &copy; {{ year }}
    </div>
    <div class="akg-logo"><a href="https://www.anykeygames.co.uk">AnyKey Games</a></div>
    <div class="tmws-logo"></div>
    <router-link to="myaccount" class="admin-login">admin login ></router-link>
  </footer>
</template>

<script>
import { computed } from 'vue';

export default {
  setup() {
    const year = computed(() => new Date().getFullYear());
    return { year };
  },
  mounted() {
    document.getElementById('menu-icon').addEventListener('click', function() {
      var navList = document.querySelector('nav ul');
      navList.classList.toggle('show');
    });
  }
};
</script>

<style lang="less">
@import '../core-styles/vars.less';

.app-footer {
  background-image: @header-footer-gradient;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: .5rem 1rem;
  color: white;

  .tmws-logo {
    background-image: data-uri("../assets/taylormadewebsolutions-logowhite.png");
    background-size: contain;
    width: 45px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .akg-logo {
    color: white;
    a {
      color: white;
      text-decoration: none;
    }
  }

  .admin-login {
    color: white;
    text-decoration: none;
  }
}
</style>
