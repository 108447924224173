import { createStore } from 'vuex';
import { UserCredential } from '@firebase/auth';
import { Player } from '@/model/player';
import { Fixture } from '@/model/fixture';
import { Team } from '@/model/team';

const store = createStore({
    state() {
        return {
            user: null,
            players: [],
            fixtures: [],
            teams: [],
            leagueTable: [],
            intervals: [],
            loading: true
        };
    },
    mutations: {
        setUser(state: any, payload: UserCredential | null) {
            state.user = payload;
        },
        setPlayers(state: any, players: Player[]) {
            state.players = players;
        },
        setFixtures(state: any, fixtures: Fixture[]) {
            state.fixtures = fixtures;
        },
        setTeams(state: any, teams: Team[]) {
            state.teams = teams;
        },
        setLeagueTable(state: any, leagueTable: Team[]) {
            state.leagueTable = leagueTable;
        },
        setLoading(state: any, payload: boolean) {
            state.loading = payload;
        }
    }
});

export default store;
