import { getAuth, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import store from '@/store';

export class AuthService {
  public static get IsLoggedIn(): boolean {
    return !!store.state.user;
  }

  public static async Login(userDetails: { email: string, password: string }): Promise<boolean> {
    try {
      const auth = getAuth();
      const signInResponse: UserCredential = await signInWithEmailAndPassword(auth, userDetails.email, userDetails.password);

      if (signInResponse.user) {
        return Promise.resolve(true);
      }
      return Promise.reject(new Error('Issue signing in'));
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public static async Logout(): Promise<void> {
    await getAuth().signOut();
  }
}
