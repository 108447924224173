<template>
  <app-header class="header"/>
  <Suspense>
    <router-view class="viewport"/>
  </Suspense>
  <app-footer class="footer"/>
  <div v-if="$store.state.loading" class="loading-screen">
    <div class="ring">Loading
      <span></span>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter
  }
};
</script>

<style lang="less">
@import './core-styles/vars.less';
@import './core-styles/core.less';
@import './core-styles/loading.less';

html,
body {
  margin: 0;
  padding: 0;
}

body {
  // background-image: @viewport-gradient;
  height: 100vh;
  scroll-behavior: smooth;
  @header-height: 100px;
  @header-footer-padding-y: 5px;
  @header-footer-padding-x: 10px;
  @total-header-height: calc(@header-height + 2*@header-footer-padding-y + 1px);

  @footer-height: 50px;
  @total-footer-height: calc(@footer-height + 2*@header-footer-padding-y + 1px);

  .header {
    height: @header-height;
    padding: @header-footer-padding-y @header-footer-padding-x;
  }

  .footer {
    height: 50px;
    padding: @header-footer-padding-y @header-footer-padding-x;
  }

  .viewport {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }
}

#app {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: @brand-dark-shade;
}
</style>
