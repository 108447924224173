import { AuthService } from '@/services/authentication';
import store from '@/store';
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

function checkAuth(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: (path?: string) => void) {
  if (AuthService.IsLoggedIn) {
    next();
  } else {
    next('/');
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/myaccount',
    name: 'myAccount',
    component: () => import('../views/UserAccount.vue')
  },
  {
    path: '/results',
    name: 'results',
    component: () => import('../views/ResultsPage.vue')
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('../views/StatsPage.vue')
  },
  {
    path: '/league',
    name: 'league',
    component: () => import('../views/LeaguePage.vue')
  },
  {
    path: '/edit-fixture/:fixtureId',
    name: 'editFixture',
    props: true,
    component: () => import('../views/EditFixture.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: () => import('../views/PageNotFoundView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((_to, _from) => {
  const navList = document.querySelector('nav ul');
  if (navList) {
    navList.classList.remove('show');
  }

  store.commit('setLoading', true);

  if (store.state.intervals.length > 0) {
    store.state.intervals.forEach((interval: number, index: number) => {
      window.clearInterval(interval);
      store.state.intervals.splice(index, 1);
    });
  }
});

export default router;
