import { createApp } from 'vue';
import App from './App.vue';
import LandingPage from './views/LandingPage.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import store from './store';
import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, getAuth } from 'firebase/auth';
import { AuthService } from './services/authentication';
import { getFirestore } from 'firebase/firestore';
import { ApiService } from './services/ApiService';

const firebaseApp = initializeApp({
    apiKey: 'AIzaSyD7X3seFFUeQfZbmq4I02alA33I63ANgh0',
    authDomain: 'football-companion-ec2ac.firebaseapp.com',
    projectId: 'football-companion-ec2ac',
    storageBucket: 'football-companion-ec2ac.appspot.com',
    messagingSenderId: '243115614847',
    appId: '1:243115614847:web:e0d33bdd57e3ea5aab154a',
    measurementId: 'G-0JVH0D3TWX'
});

let app;

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

auth.setPersistence(browserSessionPersistence);
auth.onAuthStateChanged((user) => {
    if (user) {
        store.commit('setUser', user);
    } else {
        store.commit('setUser', null);
    }
});

auth.authStateReady().then(() => {
    app = createApp(App).use(router).use(store);

    app.use(VueGtag, {
        config: {
            id: 'G-0JVH0D3TWX'
        }
    }, router);

    app.mount('#app');
}).catch((e) => {
    console.log(e);
});
