<template>
    <nav>
      <div class="nav-container">

        <h1 class="anton-font">TT '24</h1>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="league">Table</router-link></li>
          <li><router-link to="results">Results</router-link></li>
          <li><router-link to="stats">Stats</router-link></li>
        </ul>
        <div id="menu-icon">&#9776;</div>
      </div>
    </nav>
</template>

<script>

</script>

<style lang="less">
@import '../core-styles/vars.less';

.app-header {
    background-image: @header-footer-gradient;
    display: flex;
    padding: .5rem 1rem;

    h1 {
        margin-left: 1rem;
        font-family: 'Anton', sans-serif;
        .brand-core-text {
            text-shadow: -1px 0 @brand-light-shade, 0 -1px @brand-light-shade, 1px 0 @brand-light-shade, 0 1px @brand-light-shade;
            font-size: 50px;
        }
    }

    .logo {
        height: 100px;
        width: 100px;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

// old core

:root {
  --main-colour: #00696f;
  --teal-colour: #00a194;
  --light-teal: #73e2d9;
  --secondary-colour: #CDD1DE;
  --tombola-yellow: #fdfe88;
  --tombola-dark: #33355e;
  --light-green: #bdfe8d;
}

body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

section {
  min-height: 100vh;
  position: relative;
}

// footer {
//   min-height: 30vh;
// }

#teams {
  padding: 200px 0px 300px;
}

.centre-contents {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark {
  color: #33355e !important;
}

section.header {
  padding: 0% 0% 30% 0%;
}

section a {
  text-decoration: none;
  width: fit-content;
}

.title-container {
  padding: 30px;
  font-size: min(9vw, 66px);
}

.main-bg {
  background-color: var(--main-colour);
  color: white;
}

.double-drop-shadow {
  font-size: 2em;
  text-shadow: 4px 4px 0px var(--light-green), 8px 8px 0px var(--tombola-yellow);
}

.secondary-bg {
  background-color: white;
  color: var(--main-colour);
  z-index: 1;
}

.header-container {
//   background-image: url('./assets/img/beacon.jpg');
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  animation: scale-out linear;
  animation-fill-mode: forwards;
  transform-origin: top;
  animation-timeline: view();
  animation-range: exit -300px;
}

.header h2{
  margin: 0px;;
}

.header .content h1 {
  font-size: min(14vw, 150px);
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: 'Anton', sans-serif;
  font-size: min(7vw, 66px);
  text-transform: uppercase;
}

.content p {
  font-size: 1.5rem;
  text-align: center;
  width: 80%;
  text-transform: none;
}

.content h1 {
  margin: 0;
  text-align: center;
}

#support {
  padding: 20% 0% 10% 0%;
}

#cause-container {
  display: flex;
  flex-direction: column;
  padding: 0% 0%;
  margin: 3%;
}

span {
  font-weight: 600;
}

#event {
  padding: 50px 0px;
}

.cause h1 {
  text-align: center;
}

.cause {
  display: flex;
  flex-direction: column;
  padding: 10% 0%;
  margin-bottom: 50px;
  align-items: center;
}

.cause p {
  margin: 50px 0px;
  line-height: 2rem;
}

.cause .logo {
  height: 20vh;
  width: auto;
  display: flex;
  justify-content: center;
}

.logo img {
  height: inherit;
}

.pop-font {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-size: 5vw;
}

.cause h1.cause-headline {
  font-size: min(6vw, 66px);
  margin-bottom: 50px;
}

.header-container::before {
  content: ' ';
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main-colour);
  opacity: 0.8;
  position: absolute;
  animation: fade-out linear;
  animation-fill-mode: both;
  animation-timeline: view();
  animation-range: exit -50px;
}

/* buttons */
.button {
  pointer-events: all;
  cursor: pointer;
  background-color: var(--teal-colour);
  border: 5px solid var(--teal-colour);
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.5rem 1rem;
  transition: 0.2s ease-in-out;
}

.button:hover {
  background-color: transparent;
  color: var(--teal-colour);
}

/* NAV */
nav {
  background-color: var(--main-colour);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-family: 'Anton', sans-serif;
}

.nav-container {
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

section h1.light-teal-bg{
  font-size: min(4vw, 48px);
}

nav h1.anton-font {
  margin: 0;
  font-size: 2rem;
  letter-spacing: 0.3rem;
}

.anton-font {
  margin: 0;
  font-size: 3vw;
  letter-spacing: 0.4rem;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
}

nav li {
  margin-right: 20px;
  letter-spacing: 0.2rem;
}

nav li a:hover {
  color: var(--tombola-yellow);
}

nav a {
  text-decoration: none;
  color: white;
}

nav a {
  text-decoration: none;
  color: white;
}

#menu-icon {
  display: none;
}

section p {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.teal-bg {
  background-color: var(--teal-colour);
}

.light-teal-bg {
  background-color: var(--light-teal);
}

.padded-10 {
  padding: 10px;
}

@media (max-width: 768px) {
  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: var(--main-colour);
    width: 100%;
    text-align: center;
  }

  nav ul.show {
    display: flex;
    position: absolute;
    z-index: 100;
    margin-left: 0;
    padding-left: 0;
  }

  nav li {
    margin: 10px 0;
    margin-right: 0;
  }

  nav {
    flex-direction: row;
    align-items: flex-start;
  }

  #menu-icon {
    display: block;
    cursor: pointer;
    order: 1;
    user-select: none;
    font-size: 2rem;
  }
}

.shadow-animated {
  text-shadow: 1vw 1vw 0 var(--light-green), 2vw 2vw 0 var(--tombola-yellow);
}

/* animations */
@supports (animation-timeline: view()) {
  .fadeInLeft {
    animation: fade-in-left ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(100px);
    animation-timeline: view();
    animation-range-start: cover;
    animation-range-end: 30vh;
  }

  @keyframes fade-in-left {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .fadeIn {
    animation: fade-in ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-timeline: view();
    animation-range-start: cover;
    animation-range-end: 30vh;
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  .fadeOut {
    animation: fade-out linear;
    animation-fill-mode: forwards;
    animation-timeline: view();
    animation-range: exit -300px;
  }

  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }

  @keyframes scale-out {
    to {
      scale: 2;
    }
  }

  .scaleUp {
    animation: scale-up ease-in-out;
    animation-fill-mode: forwards;
    scale: 0.8;
    opacity: 0.5;
    animation-timeline: view();
    animation-range-start: cover;
    animation-range-end: 30vh;
  }

  @keyframes scale-up {
    to {
      scale: 1;
      opacity: 1;
    }
  }

  .shadow-animated {
    animation: animate-shadow ease-in-out;
    animation-fill-mode: forwards;
    animation-timeline: view();
    animation-range-start: cover;
    animation-range-end: 50vh;
    text-shadow: 0 0 0 var(--light-green), 0 0 0 var(--tombola-yellow);
  }

  @keyframes animate-shadow {
    to {
      text-shadow: 15px 15px 0 var(--light-green), 30px 30px 0 var(--tombola-yellow);
    }
  }
}
</style>
